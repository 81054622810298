import { db, auth, signInAnonymously } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

export async function log(message, type = 'info') {
  const logData = { 
    message, 
    type, 
    timestamp: new Date().toISOString() 
  };

  try {
    // Ensure the user is signed in
    if (!auth.currentUser) {
      await signInAnonymously(auth);
    }

    await addDoc(collection(db, 'logs'), logData);
    // console[type](message); // Still log to console for development
  } catch (error) {
    console.error('Error adding log to Firestore:', error);
    // Fallback to console logging if Firestore fails
    console[type](`[Fallback log] ${message}`);
  }
}

// Add global error handler
window.addEventListener('error', (event) => {
  log(`Uncaught error: ${event.message}`, 'error');
});
