import React, { useState, useEffect } from 'react';
import '../styles/index.css';

const Quiz = ({ data, onSubmit, isCorrect, showNextButton, onNextQuestion, countdownTime, incorrectMessage }) => {
  const [answer, setAnswer] = useState('');
  const [selectedDifferences, setSelectedDifferences] = useState([]);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);


  useEffect(() => {
    setAnswer('');
    setSelectedDifferences([]);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAnswerSubmitted(true);
    const currentQuestion = Array.isArray(data) ? data[0] : data;
    if (currentQuestion.type === 'spotDifference') {
      onSubmit(selectedDifferences);
    } else {
      onSubmit(answer);
    }
  };

  const renderQuestion = () => {
    const currentQuestion = Array.isArray(data) ? data[0] : data;
    // console.log("Current question data:", currentQuestion);
    if (!currentQuestion) return null;

    const renderOptions = () => {
      // console.log("Options:", currentQuestion.options);
      if (!currentQuestion.options || currentQuestion.options.length === 0) {
        return <p>No options available for this question.</p>;
      }
      return (
        <div className="flex flex-wrap gap-4 mt-2">
          {currentQuestion.options.map((option, optionIndex) => (
            <button
              key={optionIndex}
              className={`rounded-full btn ${answer === option ? 'btn-default' : 'btn-outline'}`}
              onClick={() => setAnswer(option)}
            >
              {option}
            </button>
          ))}
        </div>
      );
    };

    const renderInputField = () => (
      <input
        type="text"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        className="input input-bordered w-full max-w-xs focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
        placeholder="Type your answer here"
        autoFocus
      />
    );

    switch (currentQuestion.type) {
      case 'multipleChoice':
      case 'imageQuiz':
        return (
          <div>
            <h3 className="text-xl mb-6 font-bold">{currentQuestion.question}</h3>
            {currentQuestion.type === 'imageQuiz' && (
              currentQuestion.mediaUrl && (
                currentQuestion.mediaType === 'image' ? (
                  <img src={currentQuestion.mediaUrl} alt="Quiz" className="w-2/3 h-auto rounded-xl mb-6 shadow-lg" />
                ) : currentQuestion.mediaType === 'video' ? (
                  <video src={currentQuestion.mediaUrl} autoPlay muted loop className="w-2/3 h-auto rounded-xl mb-6 shadow-lg">
                    Your browser does not support the video tag.
                  </video>
                ) : null
              )
            )}
            {renderOptions()}
          </div>
        );
      case 'unscramble':
        return (
          <div>
            <h3 className="text-xl mb-6 font-bold">{currentQuestion.question}</h3>
            <p className="mb-2">Words to unscramble: {currentQuestion.words?.join(', ')}</p>
            {renderInputField()}
          </div>
        );
      case 'fillInTheBlank':
        return (
          <div>
            <h3 className="text-xl mb-6 font-bold">{currentQuestion.question}</h3>
            {currentQuestion.mediaUrl && (
              currentQuestion.mediaType === 'image' ? (
                <img src={currentQuestion.mediaUrl} alt="Quiz" className="w-2/3 h-auto rounded-xl mb-6 shadow-lg" />
              ) : currentQuestion.mediaType === 'video' ? (
                <video src={currentQuestion.mediaUrl} autoPlay muted loop className="w-2/3 h-auto rounded-xl mb-6 shadow-lg">
                  Your browser does not support the video tag.
                </video>
              ) : null
            )}
            {renderInputField()}
          </div>
        );
      case 'spotDifference':
        return (
          <div>
            <h3 className="text-xl mb-6 font-bold">{currentQuestion.question}</h3>
            <div className="flex justify-between mb-2 relative">
              <img src={currentQuestion.image1Url} alt="Image 1" className="w-1/2 mr-2" />
              <img src={currentQuestion.image2Url} alt="Image 2" className="w-1/2 ml-2" />
              {currentQuestion.differences?.map((diff, index) => (
                <button
                  key={index}
                  className={`absolute rounded-full ${selectedDifferences.includes(diff) ? 'bg-green-500 bg-opacity-50' : 'bg-transparent'} hover:bg-yellow-400 hover:bg-opacity-50`}
                  style={{ 
                    top: `calc(${diff.y}% - 12px)`, 
                    left: `calc(${diff.x}% - 12px)`, 
                    width: '24px', 
                    height: '24px', 
                    cursor: 'pointer',
                    border: '0px solid white',
                    boxShadow: '0 0 0 0.1px rgba(0,0,0,0.3)'
                  }}
                  onClick={() => {
                    if (selectedDifferences.includes(diff)) {
                      setSelectedDifferences(selectedDifferences.filter(d => d !== diff));
                    } else {
                      setSelectedDifferences([...selectedDifferences, diff]);
                    }
                  }}
                >
                </button>
              ))}
            </div>
          </div>
        );
      default:
        return <div>Unknown question type: {currentQuestion.type}</div>;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="quiz-container">
      {renderQuestion()}
      <button 
        type="submit" 
        className="btn btn-primary mt-10"
        disabled={showNextButton}
      >
        Submit
      </button>
      {showNextButton && (
        <button 
          onClick={onNextQuestion} 
          className="btn btn-secondary mt-6 ml-2"
        >
          Next Question {countdownTime !== null && `(${countdownTime}s)`}
        </button>
      )}
      {isCorrect && (
        <p className="text-green-500 mt-4 animate-fade-in-out">
          Yay! Correct answer!
        </p>
      )}
      {incorrectMessage && (
        <p className="text-red-500 mt-4 animate-fade-in-out">
          {incorrectMessage}
        </p>
      )}
    </form>
  );
};

export default Quiz;