import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBBqjO2VV4mc0z8NpnOC1ot9IpCv5SE9mQ",
    authDomain: "go-global-game.firebaseapp.com",
    projectId: "go-global-game",
    storageBucket: "go-global-game.appspot.com",
    messagingSenderId: "1071749599155",
    appId: "1:1071749599155:web:1d7697df322e1c4e11c5c6",
    measurementId: "G-34E6KFS6J9"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, signInAnonymously };
