import React, { useState, useEffect } from 'react';
import Home from './pages/Home';
import Game from './pages/Game';
import Finale from './pages/Finale';
import { log } from './utils/logger';

const App = () => {
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem('page');
    log(`Initial page: ${savedPage || 'home'}`);
    return savedPage || 'home';
  });
  const [gameState, setGameState] = useState(() => {
    const savedGameState = localStorage.getItem('gameState');
    const initialState = savedGameState ? JSON.parse(savedGameState) : {
      currentLevel: 1,
      location: 'Singapore'
    };
    log(`Initial game state: ${JSON.stringify(initialState)}`);
    return initialState;
  });

  useEffect(() => {
    localStorage.setItem('page', page);
    localStorage.setItem('gameState', JSON.stringify(gameState));
  }, [page, gameState]);

  const renderPage = () => {
    switch(page) {
      case 'home':
        return <Home startGame={() => setPage('game')} />;
      case 'game':
        return <Game 
          gameState={gameState} 
          setGameState={setGameState} 
          endGame={() => setPage('finale')} 
        />;
      case 'finale':
        return <Finale restartGame={() => {
          setGameState({ currentLevel: 1, location: 'Singapore' });
          setPage('home');
        }} />;
      default:
        return <Home startGame={() => setPage('game')} />;
    }
  };

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        {renderPage()}
      </div>
    </div>
  );
};

export default App;